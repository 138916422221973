module.exports = {
  siteTitle: 'International Distribution', // <title>
  manifestName: 'Gas',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-solidstate/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'International Distribution',
  subHeading: "Leading C02, N20 Gas Supplies. We have recently launched Our Cream Chargers to B2B Trade Only agents to enable their business to become an area or worldwide wholesale and retail distributor. With constant stock availability, we are committed to providing agents with the world's leading and most Cost-Effective High-Quality N2O Cream Chargers, Whipped Cream Dispensers and CO2 Cartridges.",
  // Social
  socialLinks: [

    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/gascosmoseu/',
    },

    {
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com/',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'info@internationaldistribution.net',
    },
  ],
  
  address: '19-21 Swan street, West Malling, ME19 6JU',
};
